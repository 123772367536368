import * as React from 'react'
import * as styles from './painting.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

function Painting(props) {
	const [showTitle, setShowTitle] = React.useState(false)

	const wrapperClass = showTitle ? styles.clicked : styles.wrapper
	const paintingClass = showTitle ? styles.paintingFaded : styles.painting

	function paintingToggle() {
		if (showTitle) {
			setShowTitle(false)
		} else {
			setShowTitle(true)
		}
	}
	return (
		<div className={wrapperClass} onClick={paintingToggle}>
			<GatsbyImage 
				image={props.img} 
				imgClassName={paintingClass} 
				className={styles.paintingWrapper}
				alt="" 
				src=''
				objectFit="contain"
			/>
		<h1 className={styles.title}>{props.title}</h1>
		</div>
	)
}

export { Painting }